<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Alert :message="requestError" type="alert-danger" v-if="requestError" />

    <!-- Modal start-->
    <div
      class="modal fade"
      id="createProfileModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Create Profile</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group d-flex">
              <label for="address" class="mt-2">Address:</label>
              <input
                type="text"
                id="address"
                class="form-control ml-2 w-75"
                v-model="profile.address"
                required
              />
            </div>
            <div class="form-group d-flex">
              <label for="available" class="mt-2">Available:</label>
              <input
                type="checkbox"
                id="available"
                class="form-control mt-2"
                v-model="profile.available"
                required
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              @click="createProfile"
              type="submit"
              :disabled="!profile.available"
              class="btn btn-success"
              data-dismiss="modal"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal End -->

    <!-- Modal start-->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Update Profile</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group d-flex">
              <label for="address" class="mt-2">Address:</label>
              <input
                type="text"
                id="address"
                class="form-control ml-2 w-75"
                v-model="address"
                required
              />
            </div>
            <div class="form-group d-flex">
              <label for="available" class="mt-2">Available</label>
              <input
                type="checkbox"
                id="available"
                class="form-control mt-2"
                v-model="available"
                required
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              @click="updateProfile"
              type="submit"
              :disabled="!available"
              class="btn btn-success"
              data-dismiss="modal"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal End -->

    <div class="card" v-if="loading">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-center">
          <div class="spinner-border m-5" role="status">
            <span class="visually-hidden"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="card" v-if="!loading && !success">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-center my-5">
          <p>No Resource Found. Try Again</p>
        </div>
      </div>
    </div>
    <div
      class="container card content_margin_bottom pt-4"
      v-if="!loading && success"
    >
      <div class="table-wrapper">
        <table class="table table-borderless">
          <thead>
            <tr>
              <td scope="col"><b>Full Name:</b></td>
              <td>
                {{ technician.first_name }} {{ technician.other_names }}
                {{ technician.last_name }}
              </td>
            </tr>
            <tr>
              <td scope="col"><b>Address</b></td>
              <td scope="col" v-if="technician.technician_info !== null">
                {{ technician.technician_info.address }}
              </td>
              <td v-else>
                Unknown
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><b>Availability</b></td>
              <td v-if="technician.technician_info !== null">
                {{ technician.technician_info.available }}
              </td>
              <td v-else>Unavailable</td>
            </tr>
            <tr>
              <td><b>E-mail</b></td>
              <td>{{ technician.email }}</td>
            </tr>
            <tr>
              <td><b>Phone</b></td>
              <td>
                {{ technician.phone }}
              </td>
            </tr>
            <tr>
              <td><b>User Name</b></td>
              <td>
                {{ technician.username }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-end">
          <div v-if="!technician.technician_info">
            <button
              class="btn btn-sm btn-success my-5 p-2 mr-3"
              data-toggle="modal"
              data-target="#createProfileModal"
            >
              Create Profile
            </button>
          </div>
          <div v-else class="d-flex justify-content-end">
            <button
              class="btn btn-sm btn-success my-5 p-2 mr-3"
              data-toggle="modal"
              data-target="#exampleModal"
              @click="getId(technician.technician_info.id)"
            >
              Update Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
/* eslint-disable no-unused-vars */
import toasterMixin from "@/mixins/toasterMixin.js";
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import store from "@/state/store";
import Alert from "@/components/Alert.vue";

export default {
  name: "User Course Details",
  components: {
    Layout,
    PageHeader,
    Alert,
  },
  page: {
    title: "Recruiter Profile",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Profile",
      items: [
        {
          text: "User",
        },
        {
          text: "Profile",
          active: true,
        },
      ],
      profile: {
        available: "",
        address: "",
      },
      technician: {},
      available: "",
      userId: this.$route.params.id,
      requestError: null,
      courseId: this.$route.params.courseId,
      loading: false,
      success: false,
      showCert: false,
      latitude: "",
      longitude: "",
      address: "",
      individual_id: "",
    };
  },
  methods: {
    createProfile() {
      const data = {
        longitude: this.longitude,
        latitude: this.latitude,
        address: this.profile.address,
        individual_id: parseInt(this.userId),
        available: toString(this.profile.available),
      };
      store
        .dispatch("hiring/addTechnician", data)
        .then((response) => {
          this.toast({ message: "Profile Created", type: "success" });
          this.fetchData();
          this.loading = false;
          this.success = true;
        })
        .catch(() => {
          this.requestError = "An error occured";
          this.loading = false;
          this.success = false;
        });
    },
    updateProfile() {
      const data = {
        technician_info: {
          longitude: this.longitude,
          latitude: this.latitude,
          address: this.address,
          individual_id: parseInt(this.userId),
          available: String(this.available),
          id: this.individual_id,
        },
      };
      store
        .dispatch("hiring/updateTechnician", data)
        .then((response) => {
          console.log(response);
          this.toast({ message: "Profile Updated", type: "success" });
          this.fetchData();
          this.loading = false;
          this.success = true;
        })
        .catch(() => {
          this.requestError = "An error occured";
          this.loading = false;
          this.success = false;
        });
    },
    getId(id) {
      this.individual_id = id;
    },
    showPosition(position) {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
    },
    fetchData() {
      this.loading = true;
      store
        .dispatch("hiring/getTechnician", this.userId)
        .then((response) => {
          const technician = response.data;
          this.technician = technician;

          this.loading = false;
          this.success = true;
        })
        .catch(() => {
          this.requestError = "An error occured";
          this.loading = false;
          this.success = false;
        });

      if (navigator.geolocation) {
        let fun = navigator.geolocation.getCurrentPosition(this.showPosition);
      }
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped>
b {
  font-weight: bolder;
}
.table-wrapper {
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.247);
}

#available {
  height: 20px;
}
</style>
